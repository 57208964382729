@import '../../../../styles/mixins.scss';

.litresBottomBanner {
  border: 1px solid rgba(0, 0, 0, 0.07);
  margin-top: 20px;
  &__link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  }
  &__img {
    width: 100%;
    height: auto;
    display: block;
    @include max-screen-768 {
      display: none;
    }
    &_mobile {
      display: none;
      @include max-screen-768 {
        display: block;
      }
    }
  }
}
