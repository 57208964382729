.imgFullLabel {
  position: absolute;
  bottom: -13px;
  left: 0px;
  font-size: 10px;
  padding: 2px 3px;
  line-height: 1;
  color: #fff;
  background: #f64f4f;
  letter-spacing: -0.25px;
  width: 100%;
  text-transform: lowercase;
}
