@import './../../../styles/mixins.scss';

//PopularBooks
.popularBooks {
  &_open {
    .popularBooks__item_mob {
      display: block;
    }
  }
  display: flex;
  flex-wrap: wrap;
  &__item {
    padding: 10px;
    font-size: 13px;
    width: 50%;
    @include max-screen-980 {
      width: 100%;
    }

    &_mob {
      @include max-screen-530 {
        display: none;
      }
    }

    &:nth-child(4n + 1) {
      background: #f6f6f6;
    }
    &:nth-child(4n) {
      background: #f6f6f6;
    }

    @include max-screen-980 {
      &:nth-child(odd) {
        background: #f6f6f6;
      }
    }
    @include max-screen-980 {
      &:nth-child(even) {
        background: #fff;
      }
    }

    &_full {
      width: 100%;
      background: #fefbf3 !important;
      margin-bottom: 10px;
      .newBooks__titleWrap {
        flex-direction: row;
      }
    }
  }
  &__wrap {
    display: flex;
  }
  &__itemImgWrap {
    margin-right: 10px;
  }
  &__itemImg {
    position: relative;
    display: block;
    width: 76px;
    height: auto;

    border: 1px solid rgb(236, 236, 236);
    background-color: #f2f2f2;
    &:before {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 46px;
      background: url('/icons/book-no-cover.svg') no-repeat 50%;
      background-size: cover;
      z-index: 0;
    }
  }
  &__img {
    color: transparent;
    position: relative;
    display: block;
    width: 100%;
    height: auto;
  }
  &__desc {
    width: calc(100% - 86px);
  }

  &__titleWrap {
    display: flex;
  }

  &__title {
    margin: 0 8px 5px 0;
    font-size: 14px;
    font-weight: bold;
    transition: color 0.3s ease;
    &:hover {
      color: #00ccff;
    }
  }

  &__visited {
    display: block;
    position: relative;
    margin-bottom: 3px;
    padding-left: 16px;
    color: #9f9f9f;
    margin-top: 10px;
    &_full {
      margin-top: 0;
      @include max-screen-530 {
        display: none;
      }
    }
    &_mob {
      display: none;
      @include max-screen-530 {
        display: block;
      }
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 14px;
      height: 14px;
      background: url('/icons/eye-icon.svg') no-repeat 0 0;
      background-size: contain;
    }
  }

  &__link {
    display: block;
    color: #575757;
    text-decoration: underline;
    transition: color 0.3s ease;
    margin-bottom: 3px;
    &::first-letter {
      text-transform: uppercase;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      text-decoration: none;
      color: #000;
    }
  }

  &__txt {
    display: block;
    margin-top: 10px;
    @include max-screen-530 {
      display: none;
    }
    &_mob {
      display: none;
      @include max-screen-530 {
        display: block;
      }
    }
  }
}
