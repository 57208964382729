@import '../../styles/mixins.scss';

.mainContainer {
  font-size: 14px;
  font-family: Arial, sans-serif;
  width: 100%;
  position: relative;
  max-width: 1208px;
  margin: 0 auto;
  padding: 0 10px 10px 10px;
  @include max-screen-530 {
    padding: 0 5px 5px 5px;
  }

  &__wrap {
    width: calc(100% - 480px);
    @include max-screen-1060 {
      width: calc(100% - 340px);
    }
    @include max-screen-768 {
      width: 100%;
      margin-bottom: 0;
    }
    &_fullHeight {
      display: flex;
      flex-direction: column;
      > :last-child {
        height: 100%;
      }
    }
    &_fullWidth {
      width: 100%;
      @include max-screen-1060 {
        width: 100%;
      }
    }
  }

  &__content {
    width: 100%;
    height: auto;
    display: flex;
    @include max-screen-768 {
      flex-direction: column;
    }
  }

  &__sidebar {
    width: 250px;
    margin-right: 10px;
    @include max-screen-1060 {
      width: 220px;
    }
    @include max-screen-768 {
      width: 100%;
      order: 1;
      margin: 0;
    }
    &_right {
      margin: 0 0 0 10px;
      @include max-screen-768 {
        margin: 0;
      }
    }
  }

  button,
  select,
  input,
  textarea {
    font-family: Arial, sans-serif;
    outline: none;
  }
}
