@import '../../../styles/mixins.scss';

.header {
  margin-bottom: 20px;
  @include max-screen-530 {
    margin-bottom: 10px;
  }
}

//Логотип
.logo {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin: 30px 0 24px 0;
  @include max-screen-530 {
    margin: 15px 0;
  }
  &__title {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    margin-right: 10px;
    @include max-screen-530 {
      font-size: 18px;
    }
  }
  &__subTitle {
    font-size: 19px;
    font-weight: bold;
    color: #000;
    @include max-screen-530 {
      font-size: 14px;
    }
  }
}

.menu {
  width: 100%;
  height: auto;
  padding: 6px 0;
  background-color: #e6e6e6;
  border: 1px solid #98aab1;
  margin-bottom: 20px;
  @include max-screen-530 {
    margin-bottom: 10px;
  }
  &__btn {
    display: none;
    cursor: pointer;
    @include max-screen-768 {
      display: block;
      width: 20px;
      height: 18px;
      margin-left: 15px;
      position: relative;
      top: 2px;
    }
    @include max-screen-530 {
      margin-left: 10px;
    }
  }
  &__txt {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    position: relative;
    right: -29px;
    top: -6px;
  }
}

.menuList {
  position: relative;
  display: flex;
  justify-content: space-around;
  @include max-screen-768 {
    margin-top: 15px;
    padding: 0 5px;
  }
  @include max-screen-530 {
    padding: 0;
  }
  &_mobToggle {
    @include max-screen-768 {
      display: none;
    }
  }

  &__item {
    @include max-screen-768 {
      padding: 0 10px;
      margin-bottom: 10px;
    }
  }
  &__itemLink {
    font-size: 14px;
    font-weight: bold;
    color: #000;
    transition: color 0.3s ease;
    &:hover {
      color: #00ccff;
    }
  }
}

.burger {
  > span {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    background: #7d7d7d;
    border: none;
    border-radius: 4px 4px 4px 4px;
    transition: all 0.2s, top 0.2s;
    transition-delay: 0.1s, 0s;
  }
  &__topper {
    top: 0;
  }
  &__bottom {
    top: 4px;
  }
  &__footer {
    top: 8px;
  }
}

div[menu-data='mobile-menu'] {
  .burger__topper {
    @include max-screen-768 {
      top: 6.5px;
      transform: rotate(-45deg);
    }
  }
  .burger__bottom {
    @include max-screen-768 {
      top: 4.5px;
      opacity: 0;
    }
  }
  .burger__footer {
    @include max-screen-768 {
      opacity: 1;
      top: 2.5px;
      transform: rotate(45deg);
    }
  }
  .menuList_mobToggle {
    @include max-screen-768 {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
    }
    @include max-screen-375 {
      display: block;
    }
  }
}

//Поиск
.search {
  position: relative;
  display: flex;
  align-items: center;
  &__input {
    display: block;
    width: 100%;
    height: 30px;
    border: 1px solid #c0c0c0;
    font-size: 14px;
    background: #fff;
    color: #575757;
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 24px 0 7px;
    transition: all 0.3s ease 0s;
    &::placeholder {
      color: #929292;
    }
    @include max-screen-530 {
      border-radius: 3px 0 0 3px;
      border-right: none;
    }
  }
  &__button {
    cursor: pointer;
    display: block;
    padding: 6px 30px;
    border: 1px solid #a1a1a1;
    outline: none;
    background: #f6f6f6;
    color: #575757;
    border-radius: 3px;
    margin-left: 10px;
    @include max-screen-530 {
      padding: 6px 7px;
      margin-left: 0;
      border-radius: 0 3px 3px 0;
    }
    &:hover {
      transition: color 0.3s ease, background 0.3s ease;
      color: #000;
      background: #fff;
    }
  }
}
