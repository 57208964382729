@import '../../../styles/mixins.scss';

.footer {
  width: 100%;
  height: auto;
  background: #e6e6e6;
  border: 1px solid #98aab1;
  &__content {
    width: 100%;
    max-width: 688px;
    padding: 20px;
    margin: 0 auto;
    @include max-screen-530 {
      padding: 10px;
    }
  }
  &__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @include max-screen-530 {
      flex-wrap: wrap;
      margin-bottom: 0;
    }
  }
  &__title {
    color: #909090;
    margin-bottom: 10px;
  }
  &__subMenu {
    font-size: 13px;
    @include max-screen-530 {
      margin-right: 5px;
      margin-bottom: 20px;
    }
  }
  &__subItem {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__subLink {
    color: #575757;
    text-decoration: underline;
    &:hover {
      color: black;
    }
  }
  &__bottom {
    font-size: 13px;
    color: #909090;
    display: flex;
    flex-wrap: wrap;
  }
  &__mail {
    margin-right: 20px;
    @include max-screen-530 {
      margin-right: 0;
    }
  }
  &__mailLink {
    text-decoration: underline;
    &:hover {
      color: black;
    }
  }
}
