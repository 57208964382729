@import './../../../styles/mixins.scss';

//Поиск
.searchWrap {
  margin-top: 20px;
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  &__input {
    display: block;
    width: 100%;
    height: 30px;
    border: 1px solid #c0c0c0;
    font-size: 14px;
    background: #fff;
    color: #575757;
    outline: none;
    border-radius: 3px;
    box-sizing: border-box;
    padding: 0 24px 0 7px;
    transition: all 0.3s ease 0s;
    &::placeholder {
      color: #929292;
    }
    @include max-screen-530 {
      border-radius: 3px 0 0 3px;
      border-right: none;
    }
  }
  &__button {
    cursor: pointer;
    display: block;
    padding: 6px 30px;
    border: 1px solid #a1a1a1;
    outline: none;
    background: #f6f6f6;
    color: #575757;
    border-radius: 3px;
    margin-left: 10px;
    @include max-screen-530 {
      padding: 6px 7px;
      margin-left: 0;
      border-radius: 0 3px 3px 0;
    }
    &:hover {
      transition: color 0.3s ease, background 0.3s ease;
      color: #000;
      background: #fff;
    }
  }
}

.searchControls {
  &__search {
    position: relative;
    .input {
      box-sizing: border-box;
      width: 100%;
      border: 1px solid #ccc;
      background-color: #ffffff;
      border-radius: 5px;
      padding: 10px 14px 10px 10px;
      font-size: 16px;
      &::placeholder {
        color: #ccc;
      }
    }
  }
  &__searchField {
    position: relative;
  }
  &__searchBtn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background: url('/icons/lens.svg') no-repeat 0 0;
    background-size: cover;
    border: none;
    color: transparent;
    cursor: pointer;
  }

  &__searchForm {
    display: flex;
    flex-direction: column-reverse;
  }
  &__radios {
    justify-content: flex-end;
    flex-direction: row;
    margin: 10px 0 0;
    font-size: 13px;

    &_active {
      display: flex;
    }
  }
  &__searchLabel {
    color: #a6a6a6;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #575757;
    }
  }
  &__searchInput {
    appearance: none;
    height: 12px;
    width: 12px;
    padding: 1px;
    border: 1px solid #bcbcbc;
    border-radius: 1px;
    position: relative;
    bottom: -1.2px;
    margin-right: 4px;
    &:checked {
      background-color: #1a5f9b;
      background-clip: content-box;
      color: #575757;
    }
    &:checked + .header__searchTxt {
      color: #575757;
    }
  }
}
